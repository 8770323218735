import React from 'react'
import { graphql } from 'gatsby'
import Seo from '../../components/Seo'
import Layout from './../../components/Layouts/Default'

import meta from '../../meta'

export default function BlogTemplate({ data }) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark
  const { subtitle } = meta[frontmatter.meta]

  return (
    <Layout>
      <div className="blog-posts basic">
        <Seo meta={`${frontmatter.meta}`} />
        <div className="blog-description">
          <h1 className="h3">{subtitle}</h1>
          <h6>{frontmatter.date}</h6>
          <div dangerouslySetInnerHTML={{ __html: html }} />
        </div>
        <br />
        <hr />
        <br />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        meta
      }
    }
  }
`
